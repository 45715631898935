'use client';

import ErrorContainer from '@/components/ErrorScreen/ErrorContainer';
import useApi from '@/hooks/useApi';
import {
  setDeviceType,
  setError,
  setErrorToast,
  setOrientation,
} from '@/lib/features/deviceSlice';
import {
  setLoadingState,
  setRedirectUrl,
  setUserData,
  setUserDetailsScreen,
  updateNSFWValue,
  updateUserData,
} from '@/lib/features/userSlice';
import Bugsnag, { BreadcrumbType } from '@bugsnag/js';
import BugsnagPluginReact from '@bugsnag/plugin-react';
import BugsnagPerformance from '@bugsnag/browser-performance';
import { useAppDispatch, useAppSelector } from '@/lib/hooks';
import { RootState } from '@/lib/store';
import { login, reportEvent } from '@/utils/api';
import { getFeDeviceDetails, isDesktop } from '@/utils/device';
import { v4 as uuidv4 } from 'uuid';
import { useTranslations } from 'next-intl';
import {
  useParams,
  usePathname,
  useRouter,
  useSearchParams,
} from 'next/navigation';
import { useEffect, useRef, useState } from 'react';
import { getAuth, signInAnonymously, isSignInWithEmailLink, signInWithEmailLink, EmailAuthProvider, linkWithCredential } from 'firebase/auth';
import * as firebase from 'firebase/app';
import { getFirebaseConfig } from '@/utils/firebase';
import 'groupby-polyfill/lib/polyfill.js';
import { getUtmParams, isSSR } from '@/utils';
import React from 'react';
import GoogleAnalytics from '../metrics/GoogleAnalytics';
import { useEnv } from '../context/EnvContext';

type IResponse = {
  body: {
    userId: string;
    nickName: string;
    avatarUrl: string;
    gender: string;
    ageRange: string;
    postInProgress: null | string;
    videoQuotaLeft: number;
    userCountryCode: string;
  };
  status: string;
};

type IUserResponse = {
  body: {
    user: {
    userId: string;
    nickName: string;
    avatarUrl: string;
    gender: string;
    ageRange: string;
    postInProgress: null | string;
    videoQuotaLeft: number;
    },
    userCountryCode: string;
  };
  status: string;
};

const UserLogin: React.FC = () => {
  const dispatch = useAppDispatch();
  const { envValue } = useEnv();
  const pathName = usePathname();
  const searchParams = useSearchParams();
  const [isExistingUser, setExistingUser] = useState(false);
  const [tokenGenerated, setTokenGenerated] = useState(false);
  const isSignedIn = useRef<boolean>(false);
  const { charId, feedId } = useParams();
  const chatId = searchParams.get('chatId');
  const {
    id: userDataId,
    accessToken,
    isNewUser,
    age,
    gender,
  } = useAppSelector((state: RootState) => state.user.userData);
  const ageRef = useRef<string>('');
  const genderRef = useRef<string>('');
  const t = useTranslations();

  const hostname =
    typeof window !== 'undefined' && window.location.hostname
      ? window.location.hostname
      : '';
  const telegramId = searchParams.get('telegramUserId');
  const { responseData, isLoading, error, fetchData } = useApi<IResponse>(
    'GET',
    `/ai/api/char/v1/userInfo${telegramId ? '?telegram_id=' + telegramId : ''}`
  );
  const usersPayload: {
    ageRange: string;
    gender: string;
    telegram_id?: string;
  } = {
    ageRange: 'NA',
    gender: 'NA',
  };

  if (telegramId) {
    usersPayload.telegram_id = telegramId;
  }
  const {
    responseData: userResponseData,
    error: userError,
    fetchData: userFetchData,
  } = useApi<IUserResponse>('POST', `/ai/api/char/v1/users`, usersPayload);
  const { showErrorScreen } = useAppSelector(
    (state: RootState) => state.device.deviceData
  );
  const router = useRouter();
  const handleOrientation = () => {
    let orientation;
    if (screen.orientation) {
      orientation = screen.orientation.type;
      if (orientation.includes('landscape')) {
        orientation = 'landscape';
      }
    } else {
      orientation = window.orientation;
      if (orientation == 90 || orientation == -90) {
        orientation = 'landscape';
      }
    }
    if (orientation === 'landscape' && !isDesktop()) {
      document?.body?.classList?.add('landscape');
      document?.documentElement?.classList?.add('landscape');
      dispatch(setOrientation({ orientation: 'landscape' }));
    } else {
      document?.body?.classList?.remove('landscape');
      document?.documentElement?.classList?.remove('landscape');
      dispatch(setOrientation({ orientation: 'portrait' }));
    }
  };
  const getSource = (): string => {
    const location = window.location.pathname;
    let source = 'home';
    const searchQuery = new URLSearchParams(window.location.search);
    const chatSesssionId = searchQuery.get('chatId');
    if (location.includes('/dashboard/characters/')) {
      source = 'characterDetails';
    } else if (location.includes('/dashboard/feeds/')) {
      source = 'feedDetails';
    } else if (location.includes('/dashboard/chats') && chatSesssionId) {
      source = 'chatScreen';
    } else {
      switch (location) {
        case '/sup-ai/dashboard/create': {
          source = 'create';
          break;
        }
        case '/sup-ai': {
          source = 'home';
          break;
        }
        case '/sup-ai/dashboard/feeds': {
          source = 'feeds';
          break;
        }
        case '/sup-ai/dashboard/characters': {
          source = 'explore';
          break;
        }
        case '/sup-ai/dashboard/profile': {
          source = 'profile';
          break;
        }
        case '/sup-ai/dashboard/chats': {
          source = 'chats';
          break;
        }
      }
    }
    return source;
  };
  const trackUpTime = (
    startDate: number,
    source: string,
    isFirstUptime?: boolean
  ) => {
    const extraData: {
      elapsedTime: number;
      intervalDurationSecs: number;
      isNewUser?: boolean;
      source?: string;
    } = {
      elapsedTime: (Date.now() - startDate) / 1000,
      intervalDurationSecs: 30,
    };
    if (isNewUser && isFirstUptime) {
      extraData.isNewUser = true;
    }
    extraData.source = source;
    reportEvent({
      eventName: 'feUptime',
      userId: userDataId as string,
      age: ageRef.current,
      gender: genderRef.current,
      extraData,
    });
  };
  useEffect(() => {
    ageRef.current = age || '';
    genderRef.current = gender || '';
  }, [age, gender]);
  useEffect(() => {
    if (!isLoading && responseData && responseData.status === 'Success') {
      const {
        userId,
        avatarUrl,
        nickName,
        gender,
        ageRange,
        videoQuotaLeft,
        postInProgress,
        userCountryCode
      } = responseData.body;
      dispatch(
        setUserData({
          id: userId,
          avatar: avatarUrl,
          name: nickName,
          gender,
          accessToken: accessToken,
          age: ageRange,
          videoQuotaLeft,
          postInProgress: postInProgress ? true : false,
          userPostFeedId: postInProgress,
          userCountryCode
        })
      );
      if (pathName === '/') {
        router.replace('/dashboard/characters?category=recommended');
        dispatch(setLoadingState(false));
      }
      dispatch(setUserDetailsScreen(false));
    } else if (error) {
      const { body } = error;
      if (body && body.message === t('userNotFound')) {
        const utmCampaign = sessionStorage?.getItem('utmCampaign');
        if (utmCampaign === 'roblox-link' || utmCampaign === 'telegram') {
          dispatch(setUserDetailsScreen(false));
          userFetchData();
          return;
        }
        const userId = body.authUserId;
        dispatch(
          updateUserData({
            id: userId,
            isNewUser: true,
            userVerified: false,
            likedData: {},
          })
        );
        if (chatId || charId || feedId) {
          dispatch(setRedirectUrl(window.location.href));
        }
        router.push('/');
        dispatch(setUserDetailsScreen(true));
        dispatch(setLoadingState(false));
      } else {
        reportEvent({
          eventName: 'feErrorScreen',
          userId: userDataId as string,
          age: age,
          gender: gender,
          extraData: { error: `Users Info API: ${error}` },
        });
        dispatch(setError({ showErrorScreen: true }));
        dispatch(setLoadingState(false));
      }
    }
  }, [responseData, isLoading, error]);
  useEffect(() => {
    if (userResponseData && userResponseData.status === 'Success') {
      const { user: {userId, avatarUrl, nickName, gender, ageRange}, userCountryCode } =
        userResponseData.body;
      dispatch(
        setUserData({
          id: userId,
          avatar: avatarUrl,
          name: nickName,
          gender,
          isNewUser: true,
          age: ageRange,
          userVerified: true,
          userCountryCode
        })
      );
      dispatch(setLoadingState(false));
    } else if (userError) {
      const { body } = userError;
      if (body && body.message === t('userNotFound')) {
        dispatch(
          updateUserData({
            likedData: {},
          })
        );
        router.push('/');
        dispatch(setUserDetailsScreen(true));
        dispatch(setLoadingState(false));
      } else {
        reportEvent({
          eventName: 'feErrorScreen',
          userId: userDataId as string,
          age: age,
          gender: gender,
          extraData: { error: `Users API: ${userError}` },
        });
        dispatch(setError({ showErrorScreen: true }));
        dispatch(setLoadingState(false));
      }
    }
  }, [userResponseData, userError]);
  useEffect(() => {
    dispatch(setLoadingState(true));
    const bugsnagConfig = {
      releaseStage: envValue.NEXT_PUBLIC_APP_ENV,
      appType: isSSR() ? 'server' : 'browser',
      appVersion: envValue.NEXT_PUBLIC_FE_VERSION,
      enabledBreadcrumbTypes: [
        'error',
        'navigation',
        'request',
        'user',
      ] as BreadcrumbType[],
      trackInlineScripts: false,
    };
    Bugsnag.start({
      ...bugsnagConfig,
      apiKey: '63b1dec23ba986b4da135bc99bdf9aec',
      plugins:
        typeof window === 'undefined' ? [] : [new BugsnagPluginReact(React)],
      onError: (event) => {
        event.groupingHash =
          event.originalError.message ||
          JSON.stringify(event.originalError) ||
          event.originalError.errorClass;
        if (typeof window !== 'undefined') {
          event.addMetadata('userData', {
            uaSessionId: window?.sessionStorage?.getItem('fe_uaSessionId'),
            uaId: window?.localStorage?.getItem('fe_uaId'),
            userId: userDataId,
          });
          event.addMetadata('clientInfo', {
            userAgent: navigator?.userAgent,
            visibilityState: document?.visibilityState,
            onLine: navigator?.onLine,
            utm: getUtmParams(),
            url: window?.location?.href,
            os: getFeDeviceDetails().feOsName,
            device: getFeDeviceDetails().feDeviceType,
          });
        }
      },
    });
    BugsnagPerformance.start({ apiKey: '63b1dec23ba986b4da135bc99bdf9aec' });
    const firebaseConfig = getFirebaseConfig();
    firebase.initializeApp(firebaseConfig);
    let isNSFWEnabled = false;
    if (window.localStorage) {
      isNSFWEnabled = window.localStorage.getItem('isNSFWEnabled') === 'true';
    }
    if (isNSFWEnabled) {
      dispatch(updateNSFWValue(true));
    }
    const source = sessionStorage?.getItem('utmSource');
    const campaign = sessionStorage?.getItem('utmCampaign');
    const medium = sessionStorage?.getItem('utmMedium');
    const referrer = sessionStorage?.getItem('pageReferrer');
    const utmCampaign = searchParams.get('utm_campaign') || campaign || 'NA';
    const utmSource = searchParams.get('utm_source') || source || 'NA';
    const utmMedium = searchParams.get('utm_medium') || medium || 'NA';
    const pageReferrer =  referrer || document.referrer || 'NA';
    sessionStorage?.setItem('utmCampaign', utmCampaign);
    sessionStorage?.setItem('utmSource', utmSource);
    sessionStorage?.setItem('utmMedium', utmMedium);
    sessionStorage?.setItem('pageReferrer', pageReferrer);
    if (screen.orientation) {
      screen.orientation.addEventListener('change', handleOrientation);
    } else {
      window.addEventListener('orientationchange', handleOrientation);
    }
    handleOrientation();
    const getAccessToken = async () => {
      try {
        const auth = getAuth();
        auth.onAuthStateChanged(async () => {
          const { currentUser } = auth;
          if (currentUser) {
            const isUserSignedIn = currentUser.emailVerified;
            const userEmail = currentUser.email;
            const token = await currentUser.getIdToken();
            if (!isSignedIn.current) {
              if (userEmail && !isUserSignedIn) {
                dispatch(
                  updateUserData({
                    showVerificationScreen: true,
                    email: userEmail,
                    isSignedIn: true,
                  })
                );
              } else if (isUserSignedIn) {
                login(token, currentUser.uid).then((res) => {
                  if (res === 'Fail') {
                    dispatch(setErrorToast({ showErrorToast: true }));
                  }
                });
                dispatch(
                  updateUserData({
                    showVerificationScreen: false,
                    email: userEmail || '',
                    isSignedIn: true,
                  })
                );
              }
              isSignedIn.current = true;
              setExistingUser(true);
              dispatch(updateUserData({ accessToken: token }));
            }
          } else {
            isSignedIn.current = true;
            signInAnonymously(auth).then(async (credentials) => {
              const { user } = credentials;
              const accessToken = await user.getIdToken();
              dispatch(updateUserData({ accessToken }));
            });
          }
        });
      } catch (e) {
        reportEvent({
          eventName: 'feErrorScreen',
          userId: userDataId as string,
          age: age,
          gender: gender,
          extraData: { error: `Firebase: ${e}` },
        });
        dispatch(setError({ showErrorScreen: true }));
      }
    };
    window.deferredPrompt = null;
    if ('serviceWorker' in navigator) {
      navigator.serviceWorker.getRegistrations().then(registrations => {
        for (const registration of registrations) {
            registration.unregister();
        } 
      }).catch(error => {
        console.error('Error unregistering service workers:', error);
      });
    }
    getAccessToken();
    dispatch(
      setDeviceType({
        device: getFeDeviceDetails().feDeviceType,
        os: getFeDeviceDetails().feOsName,
      })
    );
    const auth = getAuth();
    const email = searchParams.get('email');
    if (isSignInWithEmailLink(auth, window.location.href) && email) {
      // The client SDK will parse the code from the link for you.
      signInWithEmailLink(auth, email)
        .then(async (result) => {
          const token = await result.user.getIdToken();
          const email = result.user.email;
          const isEmailVerified = result.user.emailVerified;
          login(token, userDataId as string).then((res) => {
            if (res === 'Fail') {
              dispatch(setErrorToast({ showErrorToast: true }));
            } else {
              dispatch(
                updateUserData({
                  showVerificationScreen: !isEmailVerified,
                  email: email || '',
                  isSignedIn: true,
                  emailUpdated: true
                })
              );
            }
          });
        })
        .catch((error) => {
          const credential = EmailAuthProvider.credentialWithLink(
            email, window.location.href);
          const auth = getAuth();
          if (!auth.currentUser)
            return;

          linkWithCredential(auth.currentUser, credential)
            .then(async (usercred) => {
              window.localStorage.removeItem('emailForSignIn');
              const token = await usercred.user.getIdToken();
              const email = usercred.user.email;
              const isEmailVerified = usercred.user.emailVerified;
              login(token, userDataId as string).then((res) => {
                if (res === 'Fail') {
                  dispatch(setErrorToast({ showErrorToast: true }));
                } else {
                  dispatch(
                    updateUserData({
                      showVerificationScreen: !isEmailVerified,
                      email: email || '',
                      isSignedIn: true,
                      emailUpdated: true
                    })
                  );
                }
              });
            })
        }).finally(() => {
            const url = new URL(window.location.href);
            url.searchParams.delete('apiKey');
            url.searchParams.delete('oobCode');
            url.searchParams.delete('mode');
            url.searchParams.delete('lang');
            window.history.replaceState({}, document.title, url.toString());
        });
    }
    return () => {
      if (screen.orientation) {
        screen.orientation.removeEventListener('change', handleOrientation);
      } else {
        window.removeEventListener('change', handleOrientation);
      }
    };
  }, []);
  useEffect(() => {
    if (!tokenGenerated) {
      if (accessToken) {
        setTokenGenerated(true);
        fetchData();
      }
    }
  }, [accessToken, isExistingUser]);
  useEffect(() => {
    let upTimeInterval: ReturnType<typeof setInterval>;
    if (userDataId) {
      let sessionStartDate: string | null | number =
        sessionStorage?.getItem('startDate');
      if (sessionStartDate) {
        sessionStartDate = parseInt(sessionStartDate);
        sessionStorage?.removeItem('startDate');
      }
      const startDate = (sessionStartDate as number) || Date.now();
      const uaId = localStorage?.getItem('wsup_uaId');
      const uaSessionId = sessionStorage?.getItem('wsup_uaSessionId');
      if (!uaId) {
        localStorage?.setItem('wsup_uaId', `ua-${uuidv4()}`);
      }
      if (!uaSessionId) {
        sessionStorage?.setItem('wsup_uaSessionId', `uasess-${uuidv4()}`);
      }
      const intervalTime = 30000;
      const source = getSource();

      trackUpTime(startDate, source, true);
      upTimeInterval = setInterval(() => {
        if (document.visibilityState === 'visible') {
          const source = getSource();
          trackUpTime(startDate, source);
        }
      }, intervalTime);
    }
    return () => clearInterval(upTimeInterval);
  }, [userDataId]);
  return (
    <>
      {hostname === 'wsup.ai' && <GoogleAnalytics />}
      {showErrorScreen && <ErrorContainer />}
    </>
  );
};

export default UserLogin;
