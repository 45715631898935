'use client';

import ReactDOM from 'react-dom';

export function PreloadResources() {
  ReactDOM.preconnect('https://cdn.now.gg/');
  ReactDOM.prefetchDNS('https://cdn.now.gg/');

  return null;
}
