import(/* webpackMode: "eager", webpackExports: ["default"] */ "/nowgg-ai/fe/node_modules/next-intl/dist/esm/shared/NextIntlClientProvider.js");
;
import(/* webpackMode: "eager", webpackExports: ["ErrorBoundary"] */ "/nowgg-ai/fe/node_modules/next/dist/client/components/error-boundary.js");
;
import(/* webpackMode: "eager" */ "/nowgg-ai/fe/node_modules/next/font/google/target.css?{\"path\":\"src/app/layout.tsx\",\"import\":\"Rubik\",\"arguments\":[{\"weight\":[\"400\",\"500\",\"600\",\"700\"],\"style\":[\"normal\",\"italic\"],\"subsets\":[\"latin\"]}],\"variableName\":\"rubik\"}");
;
import(/* webpackMode: "eager", webpackExports: ["EnvProvider"] */ "/nowgg-ai/fe/src/app/context/EnvContext.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "/nowgg-ai/fe/src/app/error.tsx");
;
import(/* webpackMode: "eager" */ "/nowgg-ai/fe/src/app/globals.scss");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "/nowgg-ai/fe/src/app/metrics/FacebookPixel.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "/nowgg-ai/fe/src/app/metrics/MicrosoftClarity.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["PreloadResources"] */ "/nowgg-ai/fe/src/app/preload-resources.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "/nowgg-ai/fe/src/app/StoreProvider.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "/nowgg-ai/fe/src/app/tnc/GdprConsent.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "/nowgg-ai/fe/src/app/userLogin/index.tsx");
